import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import {Box, Button, FormControl, FormLabel, Input, Stack, Typography} from "@mui/joy";
import GoogleIcon from "./GoogleIcon";
import {ChevronRight, FacebookTwoTone} from "@mui/icons-material";

function App(params) {
    const onSubmit= function (){
        const urlParams = new URLSearchParams(window.location.search);
        const _location = urlParams.get('location');
        if (_location){
            window.location.replace(_location)
        }

    }
    return (
        <CssVarsProvider>
            <Box component={"main"}
                 sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh"}}>
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & bottom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <Stack spacing={2} direction={"column"} sx={{alignItems: "center"}}>
                        <Typography
                            level="h2" component="h1">
                            Are you <b>Real ?</b></Typography>
                        <Button fullWidth startDecorator={<FacebookTwoTone/>} onClick={onSubmit}>Facebook</Button>
                        <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            startDecorator={<GoogleIcon/>}
                            onClick={onSubmit}
                        >
                            Google
                        </Button>
                        <FormControl sx={{width:"100%"}}>
                            <FormLabel>Mobile Number</FormLabel>
                            <Input
                                // html input attribute
                                name="phone_number"
                                type="number"
                                placeholder="01*********"
                                startDecorator={<Typography>+88</Typography>}
                            />
                        </FormControl>
                        <Stack spacing={1} direction={"row"} sx={{width:"100%",justifyContent:"space-between"}}>
                            <Button variant={"soft"} color={"neutral"} onClick={onSubmit }>Dismiss</Button>
                            <Button  variant={"soft"} onClick={onSubmit}  endDecorator={<ChevronRight/>}>Next</Button>
                        </Stack>
                    </Stack>

                </Sheet>
            </Box>
        </CssVarsProvider>
    );
}

export default App;
